import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
  },
  {
    id: uuidv4(),
  },
  {
    id: uuidv4(),
  },
  {
    id: uuidv4(),
  },
  {
    id: uuidv4(),
  } 
];

export default dataSlider;